import vert from './deformation.vert';
// import frag from './deformation-1.frag';
import frag from './deformation.frag';

import mat4 from 'gl-mat4';

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createDeformationCommand = (regl, img) => {
  const position = [
    [-1, 1, 0],
    [-1, -1, 0],
    [1, -1, 0],
    [1, 1, 0],
  ];

  const devicePixelRatio = Math.min(window.devicePixelRatio, 2);

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
    },
    uniforms: {
      u_pixelRatio: devicePixelRatio,
      u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      u_time: ({ time }) => time,
      u_texture1: regl.texture({ data: img[0], flipY: true }),
      u_texture2: regl.texture({ data: img[1], flipY: true }),
      u_deformation: 0.5,
      u_percentVisible: 1.6,
      view: () => mat4.lookAt([], [0, 0, 1.8], [0, 0, 0], [0, 1, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 2, 1, 0.1, 1000),
    },
    count: 4,
    primitive: 'triangle fan',
    depth: { enable: true },
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
