<template lang="pug">
.stats.fixed
  video(ref='video' src='~@/assets/wave.mp4' autoplay muted loop style='width: 0; height: 0')
  img(ref='img' src='~@/assets/img-effect/pic-3.jpg' style='display: none')
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref } from 'vue';
import createCamera from 'regl-camera';
import { createWaveCommand } from '@/commands/image-effect/wave/index.js';
import createREGL from 'regl';
import { loadImg, resize } from './common';

export default {
  name: 'Wave',
  setup() {
    const canvas = ref(null);
    const video = ref(null);
    const img = ref(null);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });
      const camera = createCamera(regl, {
        center: [0, 0, 0],
        distance: 0,
        damping: 0,
        theta: 0,
        phi: 0,
        fovy: Math.PI / 8,
      });

      const draw = createWaveCommand(regl);

      try {
        await video.value.play();
        console.log('video-play');

        const textureVideo = regl.texture({ data: video.value, flipY: true });
        const textureImg = regl.texture({ data: img.value, flipY: true });

        const waveStart = () => {
          regl.frame(({ time }) => {
            regl.clear({ color: [1, 1, 1, 1] });
            camera(() => {
              draw({ time, video: textureVideo.subimage(video.value) });
              // draw({ time, video: textureImg });
            });
          });
        };

        waveStart();
      } catch (e) {
        console.error(e);
      }
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, video, img };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'
</style>
