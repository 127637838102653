<template lang="pug">
.stats.fixed
  img(ref='img' src='~@/assets/img-effect/any.jpg' style='display: none')
  img(ref='img1' src='~@/assets/img-effect/any.jpg' style='display: none')
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref, reactive } from 'vue';
import { createGridCommand } from '@/commands/image-effect/grid/grid-1/index.js';
import createREGL from 'regl';
import { loadImg, resize } from './common';

export default {
  name: 'Grid',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const img1 = ref(null);
    const pos = reactive([-1, -1]);
    const prevPos = reactive([-1, -1]);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });
      const imgEl = await Promise.all([img.value, img1.value].map((el) => loadImg(el)));
      const draw = createGridCommand(regl, imgEl);

      let speed = 0;

      regl.frame(({ time }) => {
        const slow = Math.abs(speed) * 0.2;
        speed -= slow;
        speed = Math.max(0, speed);

        draw({
          time,
          mousePos: pos.map((v, i) => (i === 0 ? v / window.innerWidth : v / window.innerHeight)),
          speed,
        });
      });

      const onMousemove = (e) => {
        prevPos[0] = pos[0];
        prevPos[1] = pos[1];
        pos[0] = e.clientX;
        pos[1] = window.innerHeight - e.clientY;
        speed += (1 * Math.abs(pos[0] - prevPos[0])) / window.innerWidth;
      };

      window.addEventListener('mousemove', onMousemove);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, img1 };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'
</style>
