<template lang="pug">
.stats.fixed
  img(ref='img' src='~@/assets/img-effect/effect.jpg' style='display: none')
  img(ref='img1' src='~@/assets/img-effect/pic-3.jpg' style='display: none')
.canvas
  canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import { createBumpCommand } from '@/commands/image-effect/cursor-bump/index.js';
import createREGL from 'regl';
import { loadImg } from './common';

const dpr = window.devicePixelRatio;

export default {
  name: 'Bump',
  setup() {
    const canvas = ref(null);
    const img = ref(null);
    const img1 = ref(null);
    const pos = reactive([-1, -1]);
    const prevPos = reactive([-1, -1]);
    const isClick = ref(0);

    let regl;

    onMounted(async () => {
      const imgRatio = 1080 / 1920;

      const onResize = () => {
        const { innerWidth, innerHeight } = window;
        const ratio = innerHeight / innerWidth;

        const renderW = ratio < imgRatio ? innerWidth : innerHeight / imgRatio;
        const renderH = ratio < imgRatio ? innerWidth * imgRatio : innerHeight;

        canvas.value.width = renderW * dpr;
        canvas.value.height = renderH * dpr;

        canvas.value.style.width = `${renderW}px`;
        canvas.value.style.height = `${renderH}px`;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({ canvas: canvas.value });
      const imgEl = await Promise.all([img.value, img1.value].map((el) => loadImg(el)));
      const draw = createBumpCommand(regl, imgEl, canvas.value);

      let lastTime = 0;
      let t = 0;
      const bumpStart = () => {
        regl.frame(({ time }) => {
          if (!isClick.value) {
            lastTime = time;
            t = 0;
          } else {
            t = time - lastTime;
          }
          draw({
            time: t,
            pos: pos.map((v) => v * dpr),
            prevPos: prevPos.map((v) => v * dpr),
            isClick: isClick.value,
          });
        });
      };

      const onMousemove = (e) => {
        prevPos[0] = pos[0];
        prevPos[1] = pos[1];
        pos[0] = e.clientX;
        pos[1] = window.innerHeight - e.clientY;
      };

      const onClick = () => {
        isClick.value = 1;
        window.removeEventListener('mousemove', onMousemove);
        pos[0] = window.innerWidth / 2;
        pos[1] = window.innerHeight / 2;
      };

      window.addEventListener('mousemove', onMousemove);
      // window.addEventListener('click', onClick);

      bumpStart();
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, img1 };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'

body
  cursor pointer

.canvas
  display flex
  justify-content center
  align-items center
  canvas
    flex-shrink 0
    cursor pointer
</style>
