import vert from './wave.vert';
import frag from './wave.frag';
// import vert from './parallax.vert';
// import frag from './parallax.frag';
import mat4 from 'gl-mat4';

/**
 * 分隔 y，层数越多，曲线越顺滑
 */
const generatePos = (layer = 10) => {
  const position = [];
  const indices = [];

  [
    ...Array(layer)
      .fill(1)
      .map((_, i) => i / layer),
    1,
  ].forEach((y, i) => {
    [-1, 1].forEach((x) => {
      position.push([x, y * 2 - 1, 0]);
    });

    if (i !== layer) {
      const start = 2 * i;
      indices.push(start, start + 1, start + 2, start + 1, start + 2, start + 3);
    }
  });

  return { position, indices };
};

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createWaveCommand = (regl) => {
  const { position, indices } = generatePos(40);
  const devicePixelRatio = Math.min(window.devicePixelRatio, 2);

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
    },
    uniforms: {
      u_pixelRatio: devicePixelRatio,
      u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      u_time: ({ time }) => time,
      u_texture_video: regl.prop('video'),
      view: () => mat4.lookAt([], [0, 0, 2], [0, 0, 0], [0, 1, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], (Math.PI * 2) / 4, 1, 0.01, 1000),
    },
    // count: position.length,
    elements: indices,
    primitive: 'triangle',
    depth: { enable: true },
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
