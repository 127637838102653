import vert from './grid.vert';
import frag from './grid.frag';
import mat4 from 'gl-mat4';

function trans(v) {
  return v * 2 - 1;
}

function seq(row = 1, column = 100) {
  const position = [];
  const uv = [];
  const indices = [];
  const center = [];
  let count = 0;

  for (let i = 0; i <= row; i++) {
    const y = i / row;

    for (let j = 0; j <= column; j++) {
      const x = j / column;

      if (i !== row && j !== column) {
        const bL = [x, y];
        const bR = [x + 1 / column, y];
        const tR = [x + 1 / column, y + 1 / row];
        const tL = [x, y + 1 / row];

        position.push(
          [...bL.map((v) => trans(v)), 0, count],
          [...bR.map((v) => trans(v)), 0, count],
          [...tR.map((v) => trans(v)), 0, count],
          [...tL.map((v) => trans(v)), 0, count],
        );

        center.push(
          ...Array(4).fill([(bL[0] + bR[0]) / 2, (bL[1] + tL[1]) / 2].map((v) => trans(v))),
        );

        uv.push([...bL], [...bR], [...tR], [...tL]);

        indices.push(count, count + 1, count + 2, count + 2, count + 3, count);

        count += 4;
      }
    }
  }

  return { position, uv, indices, center };
}

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createGridCommand = (regl, img) => {
  const devicePixelRatio = Math.min(window.devicePixelRatio, 2);

  const { position, uv, indices, center } = seq();

  return regl({
    vert,
    frag,
    attributes: {
      a_position: position,
      a_uv: uv,
      a_center: center,
    },
    uniforms: {
      u_pixelRatio: devicePixelRatio,
      u_resolution: [window.innerWidth * devicePixelRatio, window.innerHeight * devicePixelRatio],
      u_time: ({ time }) => time,
      u_mousePos: (_, { mousePos }) => mousePos.map((v) => trans(v)),
      u_speed: (_, { speed }) => speed,
      u_texture: regl.texture({ data: img[1], flipY: true }),
      view: () => mat4.lookAt([], [0, 0, 1], [0, 0, 0], [0, 1, 0]),
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], (Math.PI * 2) / 4, 1, 0.01, 1000),
    },
    elements: indices,
    primitive: 'triangle',
    depth: { enable: true },
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
